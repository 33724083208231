<template>
  <div class="personal-center">
    <a-layout class="personal-center_layout">
      <a-layout-header class="personal-center_layout-header">
        <i class="icon"></i>
        <div class="title">个人中心</div>
      </a-layout-header>
      <a-layout class="personal-center_container">
        <a-layout-sider theme="light" class="personal-center_sider">
          <!-- width="200" style="background: #fff" -->
          <div class="personal-center_userinfo">
            <div class="avatar">
              <img :src="userInfo.avatar" alt="" />
            </div>
            <!-- <a-avatar :size="64">
              <template #icon><UserOutlined /></template>
            </a-avatar> -->
            <span class="name">{{ userInfo.nickName }} </span>
            <div class="live-id-box">
              <i class="icon icon-live-id"></i>
              <span class="live-id">{{ userInfo.roomId || '暂无直播ID' }}</span>
            </div>
            <!-- <div class="title">个人中心</div> -->
          </div>
          <a-menu class="personal-center_menu" mode="inline" v-model:selectedKeys="selectedKeys">
            <a-menu-item key="1">
              <router-link to="/personal-center/baseinfo">
                <!-- <PieChartOutlined /> -->
                <span>基础资料</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="2">
              <router-link to="/personal-center/change-password">
                <!-- <PieChartOutlined /> -->
                <span>修改密码</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="3">
              <router-link to="/personal-center/user-follow">
                <!-- <PieChartOutlined /> -->
                <span>我的关注</span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="4">
              <router-link to="/personal-center/user-video">
                <!-- <PieChartOutlined /> -->
                <span>我的视频</span>
              </router-link>
            </a-menu-item>
          </a-menu>
        </a-layout-sider>
        <a-layout-content class="personal-center_content">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, watch, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const state = reactive({
      userInfo: {},
      selectedKeys: ['1']
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    onMounted(() => {
      state.userInfo = JSON.parse(localStorage.getItem('SS_userinfo'));
    });

    return {
      activeKey: ref('1'),
      ...toRefs(state)
    };
  }
});
</script>

<style lang="less" scoped>
@icon_user: '~@imgs/personal-center/icon_user.png';
@icon_live_id: '~@imgs/personal-center/icon_live_id.png';

.personal-center {
  padding-bottom: 30px;
  margin: 0 auto;
  width: 1440px;
  // height: 1060px;
  background: #f6f6f6;

  &_layout {
    margin: 30px auto 0;
    padding: 0 20px;
    width: 1440px;
    height: 1060px;
    background: #ffffff;
    border-radius: 10px;
  }

  &_container {
    background-color: transparent;
  }

  &_layout-header {
    padding: 0;
    height: 82px;
    // line-height: 82px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    background-color: transparent;
    border-bottom: 1px solid #e5e5e5;
    .icon {
      width: 20px;
      height: 20px;
      background: url('@{icon_user}') no-repeat center / 100% 100%;
    }
    .title {
      margin-left: 8px;
      width: 64px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
    }
  }

  &_sider {
    border-right: 1px solid #e5e5e5;
  }

  &_menu {
    border-right: 0;
  }

  &_userinfo {
    padding: 40px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      margin-top: 10px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
    }

    .live-id-box {
      margin-top: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-live-id {
        width: 16px;
        height: 16px;
        background: url('@{icon_live_id}') no-repeat center / 100% 100%;
      }
      .live-id {
        margin-left: 9px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 22px;
      }
    }
  }

  &_avatar {
    width: 80px;
    height: 80px;
    background: #007fff;
    border-radius: 40px;
  }

  &_content {
    padding: 30px 10px 20px 30px;
  }
}
</style>
